const en = {
  Rapporter: 'Reports',
  Rapport: 'Report',
  ADMIN: 'Administrator',
  USER: 'User',
  Tillbaka: 'Previous',
  Nästa: 'Next',
  'av val': 'of',
  'rad(er)': 'row(s)',
  Sida: 'Page',
  Sök: 'Search',
  'Inga resultat': 'No results',
  'Inga tidigare ansökningar': 'No previous applications',
  'Inga tidigare rapporter': 'No previous reports',
  'Kopiera länk': 'Copy link',
  'Kopiera ansökningsnummer': 'Copy application number',
  'Öppna ansökan': 'Open application',
  'Öppna rapport': 'Open report',
  Från: 'From',
  'Öppna meddelande': 'Open message',
  Kolumner: 'Columns',
  accountType: 'Account type',
  role: 'Role',
  emailVerified: 'Email verified',
  topic: 'Topic',
  read: 'Read',
  deletedAt: 'Deleted at',
  adminId: 'Admin ID',
  inquiryId: 'Message ID',
  adminEmail: 'Admin email',
  inquirerName: 'Inquirer name',
  inquirerEmail: 'Inquirer email',
  inquirerSenderType: 'Inquirer sender type',
  inquirerTopic: 'Inquirer topic',
  inquirerMessage: 'Inquirer message',
  adminImage: 'Admin profile image',
  senderType: 'Sender type',
  image: 'Image',
  enabled: 'Enabled',
  Fråga: 'Question',
  'Svar från': 'Answer from',
  Mottagare: 'Recipient',
  Avsändare: 'Sender',
  'Öppna användare': 'Open user',
  valda: 'selected',
  app: `app`,
  user: `user`,
  email_address: 'email address',
  account_type: 'account type',
  musician: 'musician',
  researcher: 'researcher',
  organization: 'organization',
  other: 'other',
  'Logga ut': 'Sign out',
  yes: 'yes',
  no: 'no',
  open: 'open',
  '404': 'page not found',
  '404_text':
    'Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.',
  'Våra anslag': 'Our grants',
  go_to_home: 'Go to home',
  sign_in_title: 'Sign in to KC-Fonden',
  list: `list`,
  create_user: 'Create user',
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `New Application`,
  history: `Previous Applications`,
  previous_reports: `Previous Reports`,
  new_report: `New Report`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  message: 'message',
  messages: 'messages',
  inbox: 'inbox',
  sent: 'sent',
  mode: 'Light/Dark',
  presets: 'Presets',
  settings: 'Settings',
  fullscreen: 'Full-screen',
  fullscreen_off: 'Exit full-screen',
  menu_level: `menu level`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  admin_login: 'Login as admin',
  read_more: 'read more',
  spring: 'spring',
  summer: 'summer',
  fall: 'fall',
  winter: 'winter',
  'Sidan hittades inte': 'page not found',
  'Stiftelsen Kempe-Carlgrenska Fonden': 'Stiftelsen Kempe-Carlgrenska Fonden',
  'En allmännyttig stiftelse.': 'A public benefit foundation in Sweden.',
  Ansökningar: 'Applications',
  'Ansökningar och rapporter': 'Applications and Reports',
  'Antal per sida': 'Number per page',
  'Om oss': 'About us',
  Kontakt: 'Contact us',
  Skicka: 'Send',
  Exportera: 'Export',
  'Vad vi gör': 'What We Do',
  Integritetspolicy: 'Privacy Policy',
  Integritets: 'Privacy',
  Policy: 'Policy',
  'Läs om vilka data vi samlar in': 'Read what data we collect',
  'och hur vi använder dem.': 'and how we use it.',
  'Giltighetsdatum: 2024-01-01': 'Effective Date: 2024-01-01',
  '1. Introduktion\n\nStiftelsen Kempe-Carlgrenska Fonden ("vi", "oss" eller "vår") förbinder sig att skydda din integritet och säkerheten för din personliga information. Denna integritetspolicy förklarar hur vi samlar in, använder, avslöjar och skyddar din personliga data i enlighet med gällande dataskyddslagar i Sverige.':
    '1. Introduction\n\nStiftelsen Kempe-Carlgrenska Fonden (“we,” “us,” or “our”) is committed to protecting the privacy and security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal data in accordance with the applicable data protection laws in Sweden.',
  '2. Information som Vi samlar in\n\nVi kan samla in och behandla följande typer av personlig information:\n- Kontaktinformation: Inkluderar ditt namn, e-postadress, postadress och telefonnummer.\n- Donationsinformation: Vi kan samla in information relaterad till dina donationer, inklusive betalningsuppgifter.\n- Användningsdata för Webbplats: Vi kan samla in data om dina besök på vår webbplats, som IP-adress, webbläsartyp, besökta sidor, och datum och tid för ditt besök.\n- Annan Information: All annan information som du frivilligt tillhandahåller i din interaktion med oss.':
    '2. Information We Collect\n\nWe may collect and process the following types of personal information:\n• Contact Information: This may include your name, email address, postal address, and phone number.\n• Donation Information: We may collect information related to your donations, including payment details.\n• Website Usage Data: We may gather data about your visits to our website, such as IP address, browser type, pages viewed, and the date and time of your visit.\n• Other Information: Any other information voluntarily provided by you in your interactions with us.',
  '3. Hur Vi Använder Din Information\n\nVi kan använda din personliga information för följande ändamål:\n- Att tillhandahålla information om våra verksamheter, evenemang och tjänster.\n- Att bearbeta donationer och erkänna ditt stöd.\n- Att svara på dina förfrågningar och begäranden.\n- Att förbättra vår webbplats och användarupplevelse.\n- Att följa lagliga skyldigheter.':
    '3. How We Use Your Information\n\nWe may use your personal information for the following purposes:\n• To provide information about our activities, events, and services.\n• To process donations and acknowledge your support.\n• To respond to your inquiries and requests.\n• To improve our website and user experience.\n• To comply with legal obligations.',
  '4. Delning och Avslöjande av Data\n\nVi säljer, byter eller hyr inte ut din personliga information till tredje parter. Vi kan dock dela dina uppgifter med:\n- Tjänsteleverantörer: Vi kan dela din information med betrodda tjänsteleverantörer som assisterar oss i våra verksamheter och tjänster.\n- Lagliga Krav: Vi kan avslöja din information för att uppfylla lagliga skyldigheter eller skydda våra rättigheter, integritet, säkerhet eller egendom.':
    '4. Data Sharing and Disclosure\n\nWe do not sell, trade, or rent your personal information to third parties. However, we may share your data with:\n• Service Providers: We may share your data with trusted service providers who assist us in carrying out our activities and services.\n• Legal Requirements: We may disclose your information to comply with legal obligations or protect our rights, privacy, safety, or property.',
  '5. Dina Rättigheter\n\nDu har rätt att:\n- Få tillgång till och rätta dina personuppgifter.\n- Återkalla ditt samtycke, där så är tillämpligt.\n- Begära radering av dina personuppgifter, i enlighet med lagliga krav.\n- Invända mot behandlingen av dina uppgifter under vissa omständigheter.':
    '5. Your Rights\n\nYou have the right to:\n• Access and rectify your personal data.\n• Withdraw your consent, where applicable.\n• Request the erasure of your personal data, subject to legal requirements.\n• Object to the processing of your data in certain circumstances.',
  '6. Säkerhetsåtgärder\n\nVi har implementerat rimliga säkerhetsåtgärder för att skydda din personliga information från obehörig åtkomst, avslöjande, ändring eller förstörelse.':
    '6. Security Measures\n\nWe have implemented reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.',
  '7. Kontaktinformation\n\nOm du har några frågor eller funderingar angående vår Integritetspolicy eller dina personuppgifter, vänligen kontakta oss på via kontaktformuläret.':
    '7. Contact Information\n\nIf you have any questions or concerns about our Privacy Policy or your personal data, please contact us at the contact form.',
  '8. Ändringar i Denna Policy\n\nVi kan uppdatera denna Integritetspolicy från tid till annan för att spegla förändringar i våra rutiner eller lagkrav. Eventuella uppdateringar kommer att publiceras på vår webbplats med angivande av det nya giltighetsdatumet.':
    '8. Changes to this Privacy Policy\n\nWe may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. Any updates will be posted on our website with the effective date.',
  'Genom att använda våra tjänster och tillhandahålla din personliga information samtycker du till de metoder som beskrivs i denna Integritetspolicyoch godkänner behandlingen av dina uppgifter enligt dessa riktlinjer.\n\nStiftelsen Kempe-Carlgrenska Fonden\nHälsingegatan 31\n113 31, Stockholm':
    'By using our services and providing your personal information, you consent to the practices described in this Privacy Policy.\n\nStiftelsen Kempe-Carlgrenska Fonden\nHälsingegatan 31\n113 31, Stockholm',
  Om: 'About',
  stiftelsen: 'The Foundation',
  Stiftelsen: 'Stiftelsen',
  Kempe: 'Kempe',
  Carlgrenska: 'Carlgrenska',
  Fonden: 'Fonden',
  'Läs om Stiftelsens historia': 'Learn about the history of the Foundation',
  'och grundare.': 'and its founders.',
  'Vem var Fanny?': 'Who was Fanny?',
  'Fanny Carlgren f. Kempe (1887- 1973) erhöll efter sin far Frans Kempe (vd Mo och Domsjö) ett stort arv och förvaltandet av arvet betraktade hon alltid som en mycket ansvarsfull uppgift. Detta arv lade grunden till Stiftelsen Kempe-Carlgrenska Fonden som stiftades den 28:e december 1951.':
    'Fanny Carlgren, née Kempe (1887-1973), was the daughter of Frans Kempe, who worked for several years first as the CEO and then as the chairman of Mo and Domsjö AB. After her father, Fanny Carlgren received a large inheritance, and she always considered the management of this inheritance a highly responsible task. This legacy also laid the foundation for The Kempe-Carlgrenska Foundation, established on December 28, 1951.',
  'Fanny Carlgren var intresserad av allt som gällde barn och ungdomar och de problem som kunde uppstå, vare sig det angick en organisation som arbetade med dessa eller om det gällde individen själv. De sista åren av hennes liv väckte narkotikaproblemet bland unga hennes engagemang.':
    'Fanny Carlgren was exceptionally interested, first and foremost, in everything concerning children and teenagers and the problems that could arise, whether it concerned an organization working with these groups or the individual themselves. In the last years of her life, the drug problem among the youth garnered her engagement.',
  'Efter startandet av föreningen ”Hem och skola” fick hon många framstående föreläsare till de offentliga debatter hon anordnade. Utbildning förblev ett av hennes huvudintressen och har erhållit ganska omfattande bidrag från stiftelsen både under Fanny Carlgrens ordförandetid och senare.':
    "She started an association, 'Home and School', in her time and brought many brilliant lecturers to the public debates she organized. Therefore, one of her main interests remained schools, and both Waldorf schools and other independent schools received quite extensive contributions from the foundation during Fanny Carlgren's chairmanship.",
  'Eftersom Fanny Carlgren var djupt religiös stod alltid ”kyrkan i centrum” för henne, dock långt ifrån kritiklöst. Hon var även en av dem som hjälpte Manfred Björkqvist att kunna fullgöra sitt livsverk – Sigtunastiftelsen.':
    "As Fanny Carlgren was deeply religious, the 'church was always central' to her, though far from uncritically. She was also one of those who helped Manfred Björkqvist fulfill his life's work – the Sigtuna Foundation.",
  'Fanny Carlgren var född till och förblev hela sitt liv en dotter av Norrland. Under hennes uppväxttid var Norrland Sveriges U-land och de frågor som rörde Norrland berörde henne alltid personligen.':
    "Fanny Carlgren was born and remained all her life a daughter of Norrland. During her upbringing, Norrland was Sweden's underdeveloped area, and the issues concerning Norrland always personally affected her.",
  'Vård av barn': 'Care of children',
  Vård: 'Care',
  av: 'for',
  barn: 'children',
  'Omvårdnad och stöd till barn med särskilda behov':
    'Child Care and Support for children with special needs',
  'Vilka kan söka och för vad?': 'Who and for what can one apply?',
  'Bidragsansökningar från handikappsorganisationer för all slags omvårdnad och stöd till barn/ungdom med särskilda behov. Stiftelsen ger i huvudsak bidrag till ännu inte genomförda projekt.':
    'Grant applications from disability organizations for all types of care and support to children/adolescents with special needs. The Foundation primarily provides grants to projects not yet implemented.',
  'Observera att stiftelsen prioriterar pilotprojekt framför etablerad verksamhet. Det betyder att för föreningar/organisationer som redan fått bidrag från Stiftelsen Kempe-Carlgrenska Fonden kan möjligheten att få ytterligare vara begränsad.':
    'Note that the foundation prioritizes pilot projects over established operations. This means that for associations/organizations that have already received grants from the Kempe-Carlgrenska Foundation, the opportunity to receive additional may be limited.',
  'Från ändamålsparagrafen': 'From the Purpose Paragraph',
  '"Att främja vård och uppfostran av barn"':
    '"To promote the care and upbringing of children"',
  'Sök anslag': 'Apply for grants',
  'Sök bidrag': 'Apply for grants',
  Ansökningsförfarande: 'Application procedure',
  Ansökan: 'Application',
  'Ansök under fliken Ansökningar ovan. Till ansökan tillfogas:':
    "Apply under the 'Applications' tab above. The following documents should be attached to your application:",
  'Två referenter. En referent ska vara någon med god kännedom om projektet som står utanför organisationen.':
    'Two references. One reference should be someone with thorough knowledge of the project and who is external to the organization.',
  'Budget för projektet': 'Project budget',
  'Årsredovisning. Observera att bifogad årsredovisning även ska ha specificerade resultat- och balansräkningar som lämnar väsentlig information och är transparent gällande verksamheten. En årsredovisning som t ex summerar alla kostnader till en post ger oss inte denna information. Om sökandes bifogade årsredovisning lämnar alltför knapphändig information kommer vi att se detta som en ofullständig ansökan som inte kan tas upp till behandling.':
    "Annual report. Please note that the attached annual report must also include detailed income statements and balance sheets that provide essential information and are transparent about the organization's activities. An annual report that, for example, consolidates all expenses into a single line item does not offer this level of detail. If the applicant's attached annual report provides insufficient information, we will consider this an incomplete application that cannot proceed to evaluation.",
  Verksamhetsberättelse: 'Activity report',
  Stadgar: 'Bylaws',
  'Det finns två ansökningsperioder, februari och augusti. Ansökan kan endast göras inom dessa perioder.':
    'There are two application periods, February and August. Applications can only be submitted during these periods.',
  'Du ansöker under fliken Ansökningar ovan. Utöver formuläret behövs följande dokument:':
    "You apply under the 'Applications' tab above. In addition to the form, the following documents are required:",
  Forskningsplan: 'Research plan',
  'Rekommendationsbrev/Vitsord': 'Letter of recommendation/Endorsement',
  'Intyg om institutionstillhörighet':
    'Certificate of Institutional Affiliation',
  'Sök forskningsstipendium': 'Apply for research grant',
  'Lägg till signatur': 'Insert signature',
  'Med vänliga hälsningar': 'Best regards',
  Vänligen: 'Kindly',
  'Det finns två ansökningsperioder, februari och augusti. Ansökan kan endast göras inom dessa perioder. Du kan ansöka via fliken Ansökningar ovan.':
    'There are two application periods, February and August. Applications can only be made during these periods. You can apply from the Applications link above.',
  Utbildning: 'Education',
  'Utveckling av utbildning': 'Education Development',
  'Ladda ner mall för intyg': 'Download certificate template',
  'Ladda ner exempel för Årsredovisning':
    'Download template for balance and income statement (Swedish only)',
  'Bidragsansökningar från organisationer/skolor för utveckling av nya utbildningsformer. Verksamheter som är fristående från det allmänna har oftast större prioritet än verksamheter inom kommunal regi.':
    'Grant applications from organizations/schools for the development of new forms within teaching/education. Activities that are independent from the public usually have higher priority than activities within municipal jurisdiction.',
  'Observera att ansökningar gällande skolresor, studieresor och konferenser etc inte prioriteras.':
    'Note that applications regarding school trips, study trips, and conferences, etc., are not prioritized.',
  '"Att lämna understöd för beredande av undervisning eller utbildning"':
    '"To provide support for the provision of teaching or education"',
  Integrationsarbete: 'Integration Work',
  'Stipendium ur Gösta Vestlunds Fond':
    "Scholarship from Gösta Vestlund's Fond",
  'Varje år delas 30 000 kr ut till en förening, verksamhet eller ungdomsledare som på ett för styrelsen föredömligt vis arbetar med integration av unga människor.':
    'Every year, 30,000 SEK is awarded to an association, operation, or youth leader who exemplarily works with the integration of young people, as determined by the board.',
  Hjälpbehövande: 'People in Need',
  Hjälp: 'People',
  be: 'in',
  hövande: 'Need',
  'Övrigt.': 'Other.',
  'Stiftelsen fullföljer ändamålsbestämmelsen i denna del genom att själv utse årligt bidrag till ett av oss bestämt projekt i Asien, Afrika, Baltikum eller Sverige.':
    'The foundation fulfills its purpose in this part by annually designating a grant to one of our specific projects in Asia, Africa, the Baltic States, or Sweden.',
  '"Att utöva hjälpverksamhet bland behövande"':
    '"To carry out charitable activities among the needy"',
  'Privatpersoner såväl som organisationer kan inte själva ansöka om bidrag för ändamålet hjälpbehövande. Det finns därför inget ansökningsformulär.':
    'Individuals as well as organisations are not eligible to apply for grants for this purpose.',
  Forskning: 'Research',
  'Forskning gällande barn och ungdom.':
    'Research concerning children and youth.',
  'Ansökningar från doktorander/post doc/forskare vars arbete är på hög vetenskaplig nivå och gällande barn eller ungdoms psykosociala situation och skolgång.':
    "Applications from doctoral students/post-doc/researchers whose work is at a high scientific level and concerning children or young people's psychosocial situation and schooling.",
  'Frågor och svar': 'Questions and Answers',
  'Hur stort stipendiebelopp kan jag söka?':
    'How large a scholarship amount can I apply for?',
  'Vi ger doktorandstipendier om 18 000 kr/månad och Post-doc-stipendier om 25 000 kr/månad.':
    'We give doctoral scholarships of SEK 18,000 per month and post-doc scholarships of SEK 25,000 per month.',
  'Kan jag som forskare söka forskningsanslag?':
    'Can I, as a researcher, apply for research grants?',
  'Vi delar i första hand ut stipendier till doktorander. Ibland kan vi bevilja forskningsanslag. Projektledaren dokumentera sina meriter genom ett vidimerat cv och institutionens administrative chef/motsvarande, intyga att projektet bedrivs vid den aktuella institutionen.':
    'We primarily award scholarships to doctoral students. Sometimes we can grant research grants. The project leader must document their merits through a certified CV, and the administrative head of the institution, or equivalent, must certify that the project is being conducted at the current institution.',
  'Vad kan jag som doktorand söka för typ av stöd?':
    'What kind of support can I apply for as a doctoral student?',
  'Vi delar ut stipendier vilket är en skattefri gåva till utbildning/studier.':
    'We award scholarships, which is a tax-free gift for education/studies.',
  'Jag är doktorand och vill söka ert forskningsstipendium. Vilket konto ska jag ange?':
    'I am a doctoral student and want to apply for your research scholarship. Which account should I specify?',
  'Du ska ange ditt eget plusgiro/bankgiro. Finns inget sådant kan du ange ditt bankkontonummer. Det är viktigt att du som söker forskningsstipendium inte anger forskningsinstitutets bankgiro/plusgiro eftersom forskningsstipendierna inte täcker forskningsinstitutionernas s.k. overheadkostnader.':
    "You should specify your own PlusGiro/BankGiro. If none is available, you can specify your bank account number. It is important that you, as a research scholarship applicant, do not specify the research institute's BankGiro/PlusGiro as the research scholarships do not cover the so-called overhead costs of research institutions.",
  'Jag och forskningskollegor vill åka på en konferens. Kan jag söka bidrag för detta?':
    'I and research colleagues want to go to a conference. Can I apply for a grant for this?',
  'Vi brukar ytterst sällan ge anslag till konferensresor.':
    'We very rarely grant funds for conference trips.',
  'Behöver forskningsstipendium respektive forskningsanslag redovisas?':
    'Do research scholarships and research grants need to be accounted for?',
  'Vi önskar kortfattad redogörelse för projektets resultat efter slutfört projekt. Redogörelsen görs här på hemsidan via vår ansökningplatform och är obligatorisk.':
    "Yes, we desire a brief account of the project's results after the project is completed. The report is submitted here on our website under the Contact tab.",
  'Musikstuderande på högskolenivå': 'University Level Music Student',
  Musikstuderande: 'Music-Students',
  på: 'at',
  högskolenivå: 'College-Level',
  'Våra anslag för musikstuderande på högskolenivå':
    'Our grants for music students at college level',
  Stipendier: 'Scholarships',
  'Musikstipendier delas endast ut för kommande termin, inte innevarande.':
    'Music scholarships are only awarded for the upcoming semester, not the current one.',
  'Utdelas normalt i storleksordningen 10 000 – 20 000 kronor.':
    'Usually awarded in the range of 10,000 – 20,000 kronor.',
  'Ges i första hand till instrumentalister och sångare.':
    'Primarily given to instrumentalists and singers.',
  'Ges inte retroaktivt, vilket innebär att redan avslutade studier inte beviljas medel.':
    'Not awarded retroactively, meaning funds are not given for already completed studies.',
  'Kan eventuellt prioriteras för studenter som uttömt möjlighet att erhålla CSN medel.':
    'May be prioritized for students who have exhausted the ability to obtain CSN funds.',
  'Personligt utformat brev med kort presentation över sökanden och dennes nuvarande studisituation (max 1 A4 sida).':
    'A personally formatted letter with a brief presentation of the applicant and their current study situation (max 1 A4 page).',
  'Om möjlighet att erhålla CSN medel har upphört bör sökanden styrka detta.':
    'If the ability to obtain CSN funds has been exhausted, the applicant should prove this.',
  'Ekonomisk redogörelse/budget gällande sökanden (max 1 A4 sida).':
    'Financial statement/budget regarding the applicant (max 1 A4 page).',
  'Två vidtalade refenter med telefonummer (se ansökningsformulär).':
    'Two referred referents with phone numbers (see application form).',
  'Vad menas med referenter?': 'What are referents?',
  'Vi avser i första hand en lärare till sökanden.':
    'We primarily mean a teacher to the applicant.',
  'Vad menas med ekonomisk redogörelse?':
    'What is meant by a financial statement?',
  'Att sökanden redogör inkomster och utgifter. Vissa gör en månatlig uppställning andra sammanställer inkomster/utgifter för hela året. Bägge alternativen är godtagbara. Viktigt är att redovisa ev stipendium som under året erhållits från annat håll.':
    'The applicant accounts for income and expenses. Some make a monthly compilation, others compile income/expenses for the entire year. Both options are acceptable. It is important to report any scholarships received from elsewhere during the year.',
  'Vi har ingen gräns för hur stort belopp som kan sökas men vi brukar ge stipendium i storleksordning 10 000 – 20 000 kr. Det händer att sökanden fått ett högre belopp än detta, oftast när de befinner sig längre fram i sin utbildning och kanske uttömt möjligheten till CSN lån.':
    'We have no limit on how large an amount can be applied for, but we usually grant scholarships in the order of 10,000 – 20,000 kr. It happens that the applicant has received a higher amount than this, usually when they are further along in their education and perhaps have exhausted the opportunity for CSN loans.',
  'Jag skulle behöva köpa ett bättre instrument. Kan jag söka bidrag för detta?':
    'I need to buy a better instrument. Can I apply for a grant for this?',
  'Eftersom vi är en allmännyttig stiftelse ger vi stipendier för studier (utbildning= allmännyttigt ändamål).':
    'Since we are a public benefit foundation, we give scholarships for studies (education = public benefit purpose).',
  'Hur ska stipendiet redovisas?':
    'How should the scholarship be accounted for?',
  'Kort redogörelse för studieresultat mm skall inges ett halvår efter erhållet stipendium. Redogörelsen görs här på hemsidan via vår ansökningplatform och är obligatorisk.':
    'A brief account of study results, etc., must be submitted half a year after the scholarship has been received. The account is made online and is mandatory.',
  Svenska: 'English',
  'Det här är vår svenska sida':
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  Hej: 'Hi',
  'Hej, välkommen tillbaka!': 'Hi, welcome back!',
  'Logga in med': 'Login with provider',
  'Vänligen logga in med ett Google konto för att komma till vår ansökningsportal. \nFör närvarande kan du endast logga in med ett Google konto.':
    'Please login to access our application platform. \nFor the time being you can only login with Google.',
  EDUCATION_DEVELOPMENT: 'Education Development',
  UNIVERSITY_MUSIC_STUDENT: 'University Level Music Student',
  CHILD_CARE_SUPPORT: 'Child Care and Support for children with special needs',
  YOUTH_INTEGRATION_WORK: 'Youth Integration Work',
  CHILD_YOUTH_RESEARCH: 'Child and Youth Research',
  SUPPORT_NEEDY: 'Support for the Needy',
  OTHER_QUESTIONS: 'Other Questions',
  'Integrationsarbete för ungdom': 'Youth Integration Work',
  'Forskning gällande barn och ungdom': 'Child and Youth Research',
  'Understöd till hjälpbehövande': 'Support for the Needy',
  'Övriga frågor': 'Other Questions',
  'Kontakta oss': 'Contact us',
  Kontakta: 'Contact',
  Oss: 'Us',
  'Skicka ett meddelande till Stiftelsen.': 'Send a message to the Foundation.',
  Namn: 'Namn',
  'Jag är en...': 'I am a...',
  Ämne: 'Topic',
  'Skicka meddelande': 'Send message',
  'Tack! Vi har mottagit ditt meddelande':
    'Thank you, we have recieved your message',
  'Något gick fel, kontrollera alla fält och försök igen':
    'Something went wrong, check all fields and try again.',
  'Meddelande Skickat!': 'Message sent!',
  Svara: 'Reply',
  'Något gick fel!': 'Something went wrong sending reply',
  'Din fråga:': 'Your question:',
  'Här är dina senaste ansökningar och deras status.':
    'Here you can find your latest applications and their status.',
  'Här finns alla ansökningar. Filtrera och sök för att hitta ansökningar.':
    'Here are all the applications. Filter and search as needed.',
  'Ansök om anslag': 'Apply for grants',
  'Ansökningar kan redigeras till slutet av ansökningsfönstret.':
    'Applications can be edited until our application window closes.',
  'Redigera ansökan': 'Edit application',
  Status: 'Status',
  'Ansökt belopp': 'Requested amount',
  'Ansökningsfönstret är stängt': 'Application period closed',
  'Våra ansökningsfönster är 1 februari till 28 februari, och 1 augusti till 31 augusti varje år.':
    'Our application windows are February 1st to February 28th and August 1st to August 31st every year.',
  Datum: 'Date',
  'Skapad den': 'Created on',
  'Inga ansökningar': 'No applications',
  'Skicka in ansökan': 'Submit application',
  'Vänligen fyll i alla fält för ditt konto':
    'Please complete your profile first',
  'Saknar uppgifter': 'Missing information',
  'Tack, vi har tagit emot din ansökan!':
    'Thank you, we have received your application!',
  'Något gick fel, vänligen kontrollera all fält är korrekt ifyllda':
    'Something went wrong, please check all fields are correctly filled in',
  'Vänligen fyll in följande formulär för att ansöka om anslag.':
    'Please fill in this form to apply for grants',
  utkast: 'draft',
  inlämnad: 'submitted',
  'under granskning': 'under review',
  'behöver mer info': 'needs more info',
  avslagen: 'declined',
  'beviljat anslag': 'grant given',
  DRAFT: 'draft',
  SUBMITTED: 'submitted',
  UNDER_REVIEW: 'under review',
  NEEDS_MORE_INFO: 'needs more info',
  DECLINED: 'declined',
  GRANT_GIVEN: 'grant given',
  Email: 'Email',
  email: 'email',
  applicationId: 'Application number',
  'Personnummer (tolv siffror)': 'Swedish Personal Identity Number (12 digits)',
  Adress: 'Address',
  Postnummer: 'Postal code',
  Ort: 'City',
  'Belopp som söks från Stiftelsen Kempe-Carlgrenska Fonden (kronor)':
    'Requested amount (SEK)',
  'Plusgiro/bankgiro/bankkonto': 'Bank account number',
  Kontohavare: 'Bank account holder',
  'Uppgifter om sökanden': 'Contact information',
  'Uppgifter om stipendiet och utbildningen': 'Education and grant information',
  'Finansiell information': 'Financial information',
  Referenter: 'References',
  Bilagor: 'Attachments',
  Instrument: 'Instrument',
  'Nuvarande utbildning': 'Current education',
  'Sökt utbildning': 'Sougt education',
  'Jag studerar redan på samma utbildning':
    'I am already studying in the same program',
  'Jag är ännu inte antagen': 'I have not yet been admitted',
  'Jag har blivit antagen för en kommande utbildning':
    'I have been admitted to an upcoming program',
  'Högre musikutbildning vid Kungl. Musikhögskolan ':
    'Högre musikutbildning vid Kungl. Musikhögskolan ',
  'Går första året i masterprogrammet.': 'Går första året i masterprogrammet.',
  'Om du inte redan studerar, eller har blivit antagen på en utbildning måste du komplettera antagningsbesked i efterhand.':
    'If you are not already studying, or have been admitted to a program, you must supplement your admission decision afterwards.',
  Utbildningssituation: 'Current status of your education',
  'Stipendiet kommer användas termin (höst/vår) och år':
    'Application is for year and term',
  'Har du tidigare erhållit bidrag från Stiftelsen Kempe-Carlgrenska Fonden?':
    'I have recieved grant(s) from KC-Fonden before',
  'Om ja, årtal (endast senaste anslag)': 'Last Grant Year',
  'Vidtalad referent 1: namn': 'Reference 1 Name',
  'Vidtalad referent 1: telefonnummer': 'Reference 1 Phone Number',
  'Vidtalad referent 2: namn': 'Reference 2 Name',
  'Vidtalad referent 2: telefonnummer': 'Reference 2 Phone Number',
  'Underskrivet och skannat intyg (PDF)':
    'Signed and scanned certification (PDF)',
  'Ekonomisk redogörelse (PDF)': 'Financial/Budget statement (PDF)',
  'Personligt brev (PDF)': 'Personal letter (PDF)',
  'Eventuellt styrkande av att CSN-medel ej kan erhållas (PDF)':
    'Certificate that CSN grants can no longer be obtained (PDF, optional)',
  'Antagningsbesked eller intyg om studieplats (PDF)':
    'Admission Certification or Proof of Enrollment (PDF, optional)',
  'Officiellt dokument från din utbildning som visar att du studerar eller kommer studera på den angivna utbildningen. Om du inte redan studerar på utbildningen, eller ännu inte har blivit antagen, kommer ett eventuellt stipendieanslag endast delas ut efter att antagningsbesked i efterhand kompletterats.':
    'Official document from your education that shows that you are studying or will study at the specified education. If you are not already studying in the program, or have not yet been admitted, any potential scholarship award will only be distributed after the admission notice has been supplemented afterwards.',
  Sammanfattning: 'Summary',
  'Sammanfattning av sökandens presentation innehållande nuvarande studiesituation samt vad stipendiet ska användas till (max 3400 tecken, ungefär 500 ord)':
    "Summary of the applicant's presentation, including their current study situation and how the grant will be used (max 3400 characters, approximately 500 words).",
  'Uppgifter om projectet och forskningsinstitutionen':
    'Research project and institution',
  Forskarstatus: 'Current Research Status',
  'Forskningen utförs vid institutionen för': 'Institution',
  'Institutionens administrativa chef/ekonomiskt ansvarige: namn':
    'Administrator Name',
  'Institutionens administrativa chef/ekonomiskt ansvarige: telefonnummer':
    'Administrator Phone',
  'Välj projektnivå': 'Choose project level',
  'Välj årtal': 'Choose year',
  Projektnivå: 'Project Level',
  Projekttitel: 'Project Title',
  'Forskningsplan, specifikation av sökta medel och CV (PDF)':
    'Research Plan (PDF)',
  'Rekommendationsbrev/vitsord (PDF)': 'Recommendation letter (PDF)',
  'Intyg om institutionstillhörighet (PDF)':
    'Affiliation Certification (PDF, optional)',
  'Sammanfattning av projektet, idé och syfte (max 3400 tecken, ungefär 500 ord)':
    'Summary of the project, including its idea and purpose (max 3400 characters, approximately 500 words).',
  Licentiatprojekt: 'Licentiate project',
  Doktorandprojekt: 'Doctoral project',
  'Post-doc-projekt': 'Post-doc project',
  'Projekt av hög vetenskaplig nivå': 'Project of high scientific level',
  LICENTIATE_PROJECT: 'Licentiate project',
  DOCTORAL_PROJECT: 'Doctoral project',
  POST_DOC_PROJECT: 'Post-doc project',
  PROJECT_OF_HIGH_SCIENTIFIC_LEVEL: 'Project of high scientific level',
  SPRING: 'spring',
  SUMMER: 'summer',
  FALL: 'fall',
  WINTER: 'winter',
  'Uppgifter om organisationen': 'Contact information',
  'Uppgifter om projektet': 'Project and grant information',
  'Organisationens namn': 'Organization Name',
  'Namn/företrädare': 'Representative Name',
  Organisationsnummer: 'Organization Number',
  'Har organisationen tidigare erhållit bidrag från Stiftelsen Kempe-Carlgrenska Fonden?':
    'The organization have received grant before from KC-Fonden',
  'Budget för projektet (PDF eller Excel)': 'Project Budget (PDF)',
  'Årsredovisning (balansräkning och resultaträkning) (PDF eller Excel)':
    'Annual Report (PDF)',
  'Verksamhetsberättelse (PDF)': 'Activity Report (PDF)',
  'Stadgar (PDF)': 'Organization bylaws (PDF)',
  'Ja, vi har tagit del av informationen ovan':
    'Yes, we understand and accept the information above.',
  'Bifogad årsredovisning skall även ha specificerade resultat-och balansräkningar som lämnar väsentlig information och är transparent gällande verksamheten. En årsredovisning som tex summerar alla kostnader till en post ger för oss ingen sådan värdefull information. Om sökandes bifogade årsredovisning lämnar alltför knapphändig information kommer vi att se detta som en ofullständig ansökan som inte kan tas upp till behandling.':
    "The attached annual report must also have specified income statements and balance sheets that provide substantial information and are transparent regarding the operations. \n\nAn annual report that, for example, summarizes all costs into one item does not provide such valuable information to us. \n\n If the applicant's attached annual report provides too scant information, we will consider this as an incomplete application that cannot be processed.",
  'Sammanfattning av projektet (max 3400 tecken, ungefär 500 ord)':
    'Summary of the project (max 3400 characters, approximately 500 words).',
  'Här är dina Tidigare rapporter.':
    'Here you can find your latest reports and their status.',
  'Ny rapport': 'Create new report',
  Redigera: 'Edit',
  'Redigera rapport': 'Redigera rapport',
  'Inga rapporter': 'No reports',
  'Mål och resultat': 'Goal and Results',
  'Tack, vi har tagit emot din rapport!':
    'Thank you, we have received your report!',
  'Skicka in rapport': 'Send report',
  År: 'Year',
  Termin: 'Term',
  'Allmäna uppgifter': 'General Information',
  'Vänligen beskriv de mål och resultat som uppnåtts.':
    'Please describe the goals and results achieved.',
  'Bilaga (endast PDF), frivilligt': 'Attachment (PDF only), optional',
  'Institutionens namn': 'Name of Institution',
  Bakgrund: 'Background',
  Syfte: 'Purpose',
  Metod: 'Method',
  Resultat: 'Results',
  'Redigera konto': 'Edit account',
  'Här kan du ändra eller lägga till information för ditt användarkonto.':
    'Here you can edit information about your account.',
  'Sparat!': 'Saved!',
  'Något gick fel, kontrolla alla fält och försök igen':
    'Something went wrong, check the fields and try again!',
  Spara: 'Save',
  'Ladda upp filer': 'Upload files',
  'Släpp eller välj fil': 'Drop or Select file',
  'Släpp en fil här eljer välj en fil genom att klicka här':
    'Drop files here or click browse thorough your machine',
  användare: 'user',
  mejladress: 'email address',
  kontotyp: 'account type',
  musiker: 'musician',
  forskare: 'researcher',
  organisation: 'organization',
  MUSICIAN: 'Musician',
  RESEARCHER: 'Researcher',
  ORGANIZATION: 'Organization',
  OTHER: 'other',
  annan: 'other',
  ja: 'yes',
  nej: 'no',
  Ja: 'Yes',
  Nej: 'No',
  'Ny användare': 'create user',
  öppna: 'open',
  'Tyvärr kunde vi inte hitta sidan du efterfrågade. Kanske är adresses felstavad?':
    'Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.',
  'Gå till startsidan': 'Go to home',
  'Logga in till KC-Fonden': 'Sign in to KC-Fonden',
  Lista: 'List',
  'skapa användare': 'Create user',
  redigera: 'edit',
  handla: 'shop',
  blogg: 'blog',
  inlägg: 'posts',
  meddelande: 'message',
  kort: 'cards',
  'ny ansökan': 'New application',
  'Ny ansökan': 'New application',
  'Tidigare ansökningar': 'Previous applications',
  'Tidigare rapporter': 'Previous reports',
  'ny rapport': 'New report',
  allmän: 'general',
  bank: 'banking',
  bokning: 'booking',
  profil: 'profile',
  Konto: 'Account',
  produkt: 'product',
  kvitto: 'invoice',
  detaljer: 'details',
  kassa: 'checkout',
  kalender: 'calendar',
  Analys: 'Analytics',
  'e-handel': 'e-commerce',
  Hantering: 'management',
  Meddelanden: 'messages',
  Inkorg: 'Inbox',
  Skickat: 'Sent',
  'Ljust/Mörkt': 'Light/Dark',
  Ljust: 'Light',
  Automatiskt: 'Auto',
  Mörkt: 'Dark',
  Färger: 'Presets',
  Inställningar: 'Settings',
  Helskärm: 'Full-screen',
  'Avluta helskärm': 'Exit full-screen',
  menynivå: 'menu level',
  'menynivå 2a': 'menu level 2a',
  'menynivå 2b': 'menu level 2b',
  'menynivå 3a': 'menu level 3a',
  'menynivå 3b': 'menu level 3b',
  'menynivå 4a': 'menu level 4a',
  'menynivå 4b': 'menu level 4b',
  'enhet avstängd': 'item disabled',
  etikett: 'item label',
  'enhet undertext': 'item caption',
  'enhet extern länk': 'item external link',
  beskrivning: 'description',
  'andra fall': 'other cases',
  'enheter per roll': 'item by roles',
  'Endast administratörer kan se denna enhet': 'Only admin can see this item',
  'Logga in som admin': 'Login as admin',
  'läs mer': 'read more',
  vår: 'spring',
  sommar: 'summer',
  höst: 'fall',
  vinter: 'winter',
  'Ta Bort': 'Remove',
  'Ta bort alla filer': 'Remove all files',
  'Fil Borttagen': 'File Removed',
  'Filen finns inte, eller så får du inte ta bort den':
    "File doesn't exist, or you don't have permission to remove it",
  'spara alla filer': 'download all files',
  Öppna: 'download',
  'Fil nedladdad': 'File downloaded',
  'Något gick fel, kunde inte hitta filen':
    "File doesn't exist, or you don't have permission to view it",
  Hem: 'Home',
  Anslag: 'Grants',
  Studenter: 'Musicians',
  Musikstudenterande: 'Students',
  Forskare: 'Researchers',
  'Forskning kring barn och ungdomar': 'Research',
  Organisationer: 'Organizations',
  'Barn med särskilda behov': 'Childcare',
  Övrigt: 'Other',
  'Den sidan finns inte.': 'Sorry that page isn’t here.',
  'Vi kunde inte hitta sidan du letade efter. Kanske är adressen felstavad? Var god kontrollera stavningen och försök igen.':
    'We couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.',
  'Skriv svar här': 'Write your reply here',

  // zod translations
  Required: 'Required',
  'String must contain at least 1 character(s)': 'Required',
  'String must contain at least 20 character(s)':
    'Too short. Must contain at least 20 character(s)',
  'String must contain at least 100 character(s)':
    'Too short. Must contain at least 100 character(s)',
  'String must contain at least 200 character(s)':
    'Too short. Must contain at least 200 character(s)',
  'String must contain at most 3400 character(s)':
    'Too long. Must contain at most 3400 character(s)',
  'not a valid email address': 'Invalid email',
  'Invalid email': 'Invalid email',
  "Invalid enum value. Expected 'MUSICIAN' | 'ORGANIZATION' | 'RESEARCHER' | 'OTHER', received ''":
    'Invalid account type',
  "Invalid enum value. Expected 'EDUCATION_DEVELOPMENT' | 'UNIVERSITY_MUSIC_STUDENT' | 'CHILD_CARE_SUPPORT' | 'YOUTH_INTEGRATION_WORK' | 'CHILD_YOUTH_RESEARCH' | 'SUPPORT_NEEDY' | 'OTHER_QUESTIONS', received ''":
    'Invalid option',
  "Invalid enum value. Expected 'FALL' | 'SPRING', received ''": 'Select term',
  'Name is required': 'Name is required',
  'Year must be a whole number': 'Invalid year',
  'Please select account type': 'Please select account type',
  'Invalid personal number format. Format is 19950101-1234':
    'Invalid personal number format. Format is 19950101-1234',
  'String must contain at least 3 character(s)': 'Too short',
  'Must be exactly 5 digits': 'Must be exactly 5 digits',
  'Invalid postal code format': 'Invalid postal code format',
  'Research status must be at least 1 character long':
    'Forskarstatus måste vara minst 1 tecken långt',
  'Expected boolean, received string': 'Invalid option',
  'Expected number, received string': 'Expected number, received string',
  'Invalid project level': 'Invalid project level',
  "Invalid enum value. Expected 'LICENTIATE_PROJECT' | 'DOCTORAL_PROJECT' | 'POST_DOC_PROJECT' | 'PROJECT_OF_HIGH_SCIENTIFIC_LEVEL', received ''":
    'Invalid project level',
  'Number must be greater than or equal to 1000':
    'Requested amount must be at least 1,000 SEK',
  'Only numbers': 'Only numbers',
  'Invalid organisation number foramt. Format is 123456-1234':
    'Invalid organisation number foramt. Format is 123456-1234',
  'Invalid phone number. Format can include country code like +46701234567':
    'Invalid phone number. Phone number can include country code like +46701234567',
  'Invalid url': 'Invalid file. Please make sure you have attached a file',
  "Invalid enum value. Expected 'MUSICIAN' | 'ORGANIZATION' | 'RESEARCHER', received ''":
    'Invalid account type',
  'Nytt meddelande': 'New message',
  'Nytt meddelande från': 'New message from',
  'Namn: ': 'Name: ',
  'Övriga bilagor': 'Other attachments',
  'Övriga bilagor (PDF eller Excel)': 'Other attachments (PDF or Excel)',
  'Behöver du bifoga övriga bilagor för att ge en fullgod ansökan, kan du bifoga de här.':
    'Need to attach other documents in order to get a full grant. You can attach them here.',
  'Email: ': 'Email: ',
  'Meddelande: ': 'Message: ',
  'Besök kc-fonden.se': 'Visit kc-fonden.se',
  id: 'ID',
  createdAt: 'Created (Date)',
  updatedAt: 'Updated (Date)',
  createdById: 'Created By ID',
  updatedById: 'Updated By ID',
  createdBy: 'Created By',
  updatedBy: 'Updated By',
  kind: 'Type',
  emailAddress: 'Email Address',
  name: 'Name',
  address: 'Address',
  postalCode: 'Postal Code',
  city: 'City',
  receivedGrantBefore: 'Received Grant Before',
  lastGrantYear: 'Last Grant Year',
  requestedAmount: 'Requested Amount',
  bankAccountNumber: 'Bank Account Number',
  bankAccountHolder: 'Bank Account Holder',
  projectSummary: 'Project Summary',
  signedCertUrl: 'Signed Certificate URL',
  status: 'Status',
  personalNumber: 'Personal Number',
  researchStatus: 'Research Status',
  institution: 'Institution',
  adminName: "Administrator's Name",
  adminPhone: "Administrator's Phone",
  projectLevel: 'Project Level',
  projectTitle: 'Project Title',
  researchPlanUrl: 'Research Plan URL',
  recommendationUrl: 'Recommendation Letter URL',
  affiliationCertUrl: 'Affiliation Certificate URL',
  instrument: 'Instrument',
  currentEducation: 'Current Education',
  soughtEducation: 'Sought Education',
  educationSituation: 'Education Situation',
  termAndYear: 'Term and Year',
  reference1Name: 'Reference 1 Name',
  reference1Phone: 'Reference 1 Phone',
  reference2Name: 'Reference 2 Name',
  reference2Phone: 'Reference 2 Phone',
  financialStatementUrl: 'Financial Statement URL',
  personalLetterUrl: 'Personal Letter URL',
  CSNCertUrl: 'CSN Certificate URL',
  admissionCertUrl: 'Admission Certificate URL',
  orgName: 'Organization Name',
  representativeName: "Representative's Name",
  orgNumber: 'Organization Number',
  projectBudgetUrl: 'Project Budget URL',
  annualReportUrl: 'Annual Report URL',
  activityReportUrl: 'Activity Report URL',
  otherAttachmentsUrl: 'Other Attachments URL',
  otherAttachmentUrl: 'Övriga bilagor URL',
  bylawsUrl: 'Bylaws URL',
  acceptedTerms: 'Accepted Terms',
  'You must accept the terms': 'You must accept the terms',
  grantYear: 'Grant Year',
  grantSemester: 'Grant Semester',
  summary: 'Summary',
  attachments: 'Attachments',
  timestamp: 'Timestamp',
  visitorId: "Visitor's ID",
  pageVisited: 'Page Visited',
  userAgent: 'User Agent',
  ipAddress: 'IP Address',
  country: 'Country',
  region: 'Region',
  referer: 'Referer',
  true: 'Yes',
  false: 'No',
  Avbryt: 'Cancel',
  'Är du säker på att du vill skicka detta svar?':
    'Are you sure you want to send this reply?',
  'Välkommen tillbaka': 'Welcome back',
  "Det här är Stiftelsen Kempe-Carlgrenska Fonden's Dashboard, som ger insikter om våra användare och ansökningstrender.":
    "This is Stiftelsen Kempe-Carlgrenska Fonden's Dashboard, which provides insights into our users and application trends.",
  'Besökare (totalt/per månad)': 'Visitors (total/per month)',
  'Registrerade användare (totalt/per månad)':
    'Registered users (total/per month)',
  'Meddelanden (totalt/per månad)': 'Messages (total/per month)',
  'Svarade meddelanden (totalt/per månad)':
    'Replied messages (total/per month)',
  'Ansökningar (totalt)': 'Applications (total)',
  Månadsvis: 'Monthly',
  Årlig: 'Yearly',
  'Svaret måste vara minst 20 tecken': 'Reply must be at least 20 characters',
} as const;

export default en;
