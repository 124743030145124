'use client';

import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import React, { createContext, useContext, useState } from 'react';
import sv from '~/dictionaries/sv';
import en from '~/dictionaries/en';

interface TranslationContextProps {
  dictionary: Dictionary;
  setLocale: (locale: AppLocale) => void;
  lang: AppLocale; // Added current locale
}

const TranslationContext = createContext<TranslationContextProps>({
  dictionary: {} as Dictionary,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLocale: () => {},
  lang: 'sv' as AppLocale, // Default value
});

type Props = {
  children: React.ReactNode;
};

export default function TranslationProvider({ children }: Props) {
  const pathName = usePathname();
  const searchParams = useSearchParams();
  const router = useRouter();
  const [dictionary, setDictionary] = useState<Dictionary>({} as Dictionary);

  const setLocale = (locale: AppLocale) => {
    // Update the dictionary
    const newDictionary = locale === 'sv' ? sv : en;
    setDictionary(newDictionary);

    // Remove the current locale from the pathname
    const newPath = pathName.startsWith(`/${getCurrentLocale()}`)
      ? pathName.replace(`/${getCurrentLocale()}`, `/${locale}`)
      : `/${locale}${pathName}`;

    const searchParamsString = searchParams.toString();
    const newUrl = searchParamsString
      ? `${newPath}?${searchParamsString}`
      : newPath;

    // Navigate to the new path
    router.push(newUrl);
  };

  const getCurrentLocale = (): AppLocale => {
    const loc = pathName.split('/');
    return loc.length > 1 ? (loc[1] as AppLocale) : 'sv';
  };

  return (
    <TranslationContext.Provider
      value={{ dictionary, setLocale, lang: getCurrentLocale() }}
    >
      {children}
    </TranslationContext.Provider>
  );
}

export const useTranslation = () => {
  const context = useContext(TranslationContext);
  return context;
};
